import React from "react";
import {Link} from "gatsby";

const Navbar = () => {
  return (
    <div>
      <div className=" left-0 right-0 bg-white z-50">
        <div className=" py-2 flex justify-between">
          <div className="flex justify-between ">
            <Link to="/" className="nav-item hover:text-green-default">
              Idea
            </Link>
            <Link
              to="/inspiration"
              className="nav-item hover:text-green-default "
            >
              Inspiration
            </Link>
          </div>
          <div className="flex justify-between">
            <div className="relative diy">
              <Link to="/diy-editions-6" className="nav-item hover:text-green-default">
                DIY-editions
              </Link>
              <div className="bg-white dropdown w-80 px-3 py-5 shadow-xl absolute left-0 right-0">
                <ul className=" px-4">
                  <li className="py-2.5 border-b border-gray-500">
                    <a className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold">
                      <Link to="/first-edition">Bioblinds 1st Edition IVY</Link>
                    </a>
                  </li>
                  <li className="py-2.5 border-b border-gray-500">
                    <a className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold">
                      <Link to="/bioblinds-2nd-edition-cowshed">
                        Bioblinds 2nd Edition COWSHED
                      </Link>
                    </a>
                  </li>
                  <li className="py-2.5 border-b border-gray-500">
                    <a className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold">
                      <Link to="/plant-research-hub">
                        Bioblinds plant research hub
                      </Link>
                    </a>
                  </li>
                  <li className="py-2.5">
                    <a
                      href="https://www.indiegogo.com/projects/bioblinds-com#/"
                      target="__blank"
                      className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold"
                    >
                      Support us on Indiegogo
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <Link
                to="/get-in-touch"
                className="nav-item hover:text-green-default"
              >
                Get in touch
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
