import React, { useState } from "react";
import Logo from "./../../images/logo_aktuell.png";

const Header = () => {
  const [state, setstate] = useState(false);
  return (
    <div className="max-w-7xl  mx-auto  ">
      <div className=" flex justify-center text-center ">
        <div className=" w-full">
          <img
            src={Logo}
            alt=""
            width="507"
            className={`${
              state ? "fixed md:pt-0 md:mb-0" : "md:pt-6 md:mb-12 md:block hidden"
            } m-auto right-0 left-0`}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
