import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
const Layout = ({children}) => {
  return (
    <div>
      <div className="block md:hidden">
        <MobileMenu />
      </div>
      <div className="hidden md:block">
        <Navbar />
      </div>

      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
