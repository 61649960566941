import React from "react";
import {Link} from "gatsby";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="flex justify-between">
        <div className="flex items-center ">
         <p className="copy-right w-auto">
            Licensed under <a className="hover:text-green-default" href="https://creativecommons.org/licenses/by/4.0/deed.en" target="__blank">CC BY 4.0</a><br/> 
            © 2021 Bioblinds – The Green Skyline Initiative
          </p>
          <p className="Impressum lg:block hidden">
            <Link
                to="/get-in-touch"
                className="hover:text-green-default"
              >Impressum/Datenschutz/Kontakt</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
