import React, {useState} from "react";
import Drawer from "react-modern-drawer";
import {Link} from "gatsby";
import {FaBars} from "react-icons/fa";
import logo from "./../../images/mobilelogo.png";
import "react-modern-drawer/dist/index.css";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className="w-full ">
        <div className="flex justify-between px-5 py-5">
          <div>
            <Link to="/">
              <img src={logo} alt="Bioblinds Logo" className="w-40" />
            </Link>
          </div>
          <div className="flex self-center">
            <FaBars onClick={toggleDrawer} className="w-7 h-7 cursor-pointer  " />
          </div>
        </div>

        <Drawer open={isOpen} onClose={toggleDrawer} direction="left">
          <ul className=" px-4">
            <li className="py-1.5">
              <Link to="/">
                <a className="text-lg border rounded-md border-white py-1.5 cursor-pointer text-darkhover:text-darkfont-semibold px-6 my-4 ">
                  <img src={logo} alt="Bioblinds Logo" />
                </a>
              </Link>
            </li>
            <li className="py-2.5 border-b border-gray-500">
              <a className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold">
                <Link to="/"> Idea </Link>
              </a>
            </li>
            <li className="py-2.5 border-b border-gray-500">
              <a className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold">
                <Link to="/inspiration"> Inspiration </Link>
              </a>
            </li>
            <li className="py-2.5 border-b border-gray-500">
              <a className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold">
                <Link to="/diy-editions-6">DIY-editions</Link>
              </a>
            </li>
            <li className="py-2.5 border-b border-gray-500">
              <a className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold">
                <Link to="/first-edition">Bioblinds 1st Edition IVY</Link>
              </a>
            </li>
            <li className="py-2.5 border-b border-gray-500">
              <a className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold">
                <Link to="/bioblinds-2nd-edition-cowshed">
                  Bioblinds 2nd Edition COWSHED
                </Link>
              </a>
            </li>
            {/*<li className="py-2.5 border-b border-gray-500">
              <a className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold">
                <Link to="/plant-research-hub">
                  Bioblinds plant research hub
                </Link>
              </a>
            </li>
           
            <li className="py-2.5 border-b border-gray-500">
              <a
                href="https://www.indiegogo.com/projects/bioblinds-com#/"
                className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold"
              >
                Support us on Indiegogo
              </a>
            </li>
            */}
            <li className="py-2.5 border-b border-gray-500">
              <a className="text-lg cursor-pointer text-darkhover:text-darkfont-semibold">
                <Link to="/get-in-touch"> Get in touch </Link>
              </a>
            </li>
          </ul>
        </Drawer>
      </div>
    </>
  );
};

export default MobileMenu;
